import React from 'react'
import Page from '../Layout/Page'
import PageWithPartners from '../Layout/PageWithPartners'
import { graphql } from 'gatsby'
function Projects({
  data: {
    directus: {
      page: { title, hero, items, partners },
    },
  },
}) {
  return (
    <Page selected='Projects' title='Projects' description="Ahmed meftah's projects">
      <PageWithPartners
        nav={{
          title: hero.title,
          bg1: hero.background1,
          bg2: hero.background2,
          color: hero.textColor,
          waveBg1: '#ffffff',
          waveBg2: '#ffffff',
        }}
        items={items}
        partners={partners}
      />
    </Page>
  )
}

export default Projects

export const query = graphql`
  query projectlist {
    directus {
      page: Partner_page_by_id(id: "cbfa978e-bdbb-4cd7-8a4d-2c43fdc8e05e") {
        title
        hero {
          background1
          background2
          textColor
          title
        }
        partners {
          id
          partner_id {
            name
            link
            id
            thumbnail {
              id
              imageFile {
                id
                childImageSharp {
                  gatsbyImageData(width: 300, height: 300, quality: 100)
                }
              }
            }
          }
        }
        items {
          id
          item {
            ... on DirectusData_project {
              id
              name
              link
              thumbnail {
                id
                title
                imageFile {
                  childImageSharp {
                    gatsbyImageData(width: 300, height: 300, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
